<template>
	<!-- 资金明细列表 -->
	<div class="customerGroup">
		<div class="table">
			<div class="table-operation">
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="创建时间" prop="created_at" align="center" width="160"></el-table-column>
				<el-table-column label="期初金额" prop="old_money" align="center" width="120"></el-table-column>
				<el-table-column label="类型" prop="type" align="center" width="160">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">订单核销(增加)</span>
						<span v-if="scope.row.type == 2">购买商品(减少)</span>
						<span v-if="scope.row.type == 3">退款(减少)</span>
						<span v-if="scope.row.type == 4">冻结(减少)</span>
						<span v-if="scope.row.type == 5">解冻(增加)</span>
						<span v-if="scope.row.type == 6">提现(减少)</span>
						<span v-if="scope.row.type == 7">交易服务费(减少)</span>
						<span v-if="scope.row.type == 8">售后(减少)</span>
						<span v-if="scope.row.type == 9">服务商分佣(增加)</span>
						<span v-if="scope.row.type == 10">邀新奖励(增加)</span>
					</template>
				</el-table-column>
				<el-table-column label="本期金额" prop="money" align="center" width="120">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1" style="color:red">{{scope.row.money}}</span>
						<span v-if="scope.row.type == 2" style="color:green">{{scope.row.money}}</span>
						<span v-if="scope.row.type == 3" style="color:green">{{scope.row.money}}</span>
						<span v-if="scope.row.type == 4" style="color:green">{{scope.row.money}}</span>
						<span v-if="scope.row.type == 5" style="color:red">{{scope.row.money}}</span>
						<span v-if="scope.row.type == 6" style="color:green">{{scope.row.money}}</span>
						<span v-if="scope.row.type == 7" style="color:green">{{scope.row.money}}</span>
						<span v-if="scope.row.type == 8" style="color:green">{{scope.row.money}}</span>
						<span v-if="scope.row.type == 9" style="color:red">{{scope.row.money}}</span>
						<span v-if="scope.row.type == 10" style="color:red">{{scope.row.money}}</span>
					</template>
				</el-table-column>
				<el-table-column label="期末金额" prop="new_money" align="center" width="120"></el-table-column>
				<el-table-column label="标题" prop="title" align="center" width="200"></el-table-column>
				<el-table-column label="内容" prop="content" align="center"></el-table-column>
			</Table>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 20,
				total: 0,
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`erp/v1/wallet/lst`, {
					page: this.page,
					pageNum: this.pageNum,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			}
		},
	}
</script>

<style lang="scss" scoped></style>